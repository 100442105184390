import { RouteRecordRaw } from 'vue-router';
import { paymentsRoutesDictionary } from './payments-routes.dictionary.ts';

export const paymentsRoutesConfig: RouteRecordRaw = {
  ...paymentsRoutesDictionary.payments.root,
  redirect: { name: paymentsRoutesDictionary.payments.list.name },
  children: [
    {
      ...paymentsRoutesDictionary.payments.list,
      component: () => import('../pages/List/PaymentListPage.vue'),
    },
  ],
};
