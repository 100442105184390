import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const reimbursementRoutesDictionary = moduleRouteDictionaryFactory(
  'reimbursement',
  'reimbursements',
  {
    list: 'list',
    detail: 'detail/:id',
  },
);
