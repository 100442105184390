import { fringeRoutesDictionary } from './../../modules/app/fringe/routes/fringe-routes.dictionary';
import { moduleRouteDictionaryFactory } from '@tundr/routing';
import { bankTransfersRoutesDictionary } from '../../modules/app/bank-transfers/routes/bank-transfers-routes.dictionary.ts';
import { companyRoutesDictionary } from '../../modules/app/company/routes/company-routes.dictionary.ts';
import { reimbursementRoutesDictionary } from '../../modules/app/reimbursement/routes/reimbursement-routes.dictionary.ts';
import { suppliersRoutesDictionary } from '../../modules/app/suppliers/routes/suppliers-routes.dictionary.ts';
import { authRoutesDictionary } from '../../modules/auth/routes/auth-routes.dictionary';
import { subscriptionRoutesDictionary } from '../../modules/app/subscription/routes/subscription-routes.dictionary.ts';
import { employeeRoutesDictionary } from './../../modules/app/employee/routes/employee-routes.dictionary';
import { reportsRoutesDictionary } from './../../modules/app/reports/routes/reports-routes.dictionary.ts';
import { overBudgetEmployeeRoutesDictionary } from '../../modules/app/over-budget-employee/routes/over-budget-employee.dictionary.ts';
import { billingRoutesDictionary } from '../../modules/app/billing/routes/billing-routes.dictionary.ts';
import { paymentsRoutesDictionary } from '../../modules/app/payments/routes/payments-routes.dictionary.ts';
import { cardOrderRoutesDictionary } from '../../modules/app/card-orders/routes/card-order-routes.dictionary.ts';
import { usersRoutesDictionary } from '../../modules/app/users/routes/users-routes.dictionary.ts';

const rootRoutesDictionary = moduleRouteDictionaryFactory('root', '/', {
  notFound: '/:pathMatch(.*)*',
  app: '/app',
});

export const appRoutesDictionary = {
  ...rootRoutesDictionary,
  ...authRoutesDictionary,
  ...employeeRoutesDictionary,
  ...suppliersRoutesDictionary,
  ...companyRoutesDictionary,
  ...reportsRoutesDictionary,
  ...reimbursementRoutesDictionary,
  ...bankTransfersRoutesDictionary,
  ...subscriptionRoutesDictionary,
  ...overBudgetEmployeeRoutesDictionary,
  ...billingRoutesDictionary,
  ...fringeRoutesDictionary,
  ...paymentsRoutesDictionary,
  ...cardOrderRoutesDictionary,
  ...usersRoutesDictionary,
};
