<script lang="ts" setup>
  import { useAuthStore } from '@tundr/auth';
  import { useLocale } from '@tundr/i18n';
  import { ToastVariant, textEllipsisClass } from '@tundr/theme';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { useToast } from '@tundr/toast';
  import { FontIcon, Menu, MenuItem, PlacementOptions } from '@tundr/ui-kit';
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';
  import {
    menuTitleClass,
    userIconCollapsedClass,
    userNameBoxClass,
    userNameClass,
  } from './UserMenu.css';
  import { appRoutesDictionary } from '../../../core/router/routes.dictionary';

  type UserMenuProps = {
    collapsed: boolean;
  };

  const props = defineProps<UserMenuProps>();

  const { t } = useLocale('common');
  const authStore = useAuthStore();
  const router = useRouter();
  const { show } = useToast();

  const onLogout = () => {
    authStore.logout();
    show({
      message: t('profile_menu.actions.logout.success.title'),
      description: t('profile_menu.actions.logout.success.description'),
      variant: ToastVariant.SUCCESS,
    });
    router.replace({ name: appRoutesDictionary.auth.login.name });
  };

  const triggerIcon = computed(() => {
    if (props.collapsed) {
      return UNICONS_ICONS.USER_CIRCLE;
    }
    return UNICONS_ICONS.ANGLE_DOWN;
  });
</script>

<template>
  <div :class="userNameBoxClass">
    <div v-if="!collapsed" :class="userNameClass">
      <FontIcon :iconName="UNICONS_ICONS.USER_CIRCLE" />
      <span :class="textEllipsisClass"
        >{{ authStore.user?.firstName }} {{ authStore.user?.lastName }}</span
      >
    </div>
    <Menu :placement="PlacementOptions.BOTTOM_END">
      <template #trigger>
        <FontIcon
          :test-id="'user-menu-trigger'"
          :title="
            collapsed
              ? `${authStore.user?.firstName} ${authStore.user?.lastName}`
              : ''
          "
          clickable
          :icon-name="triggerIcon"
          :class="collapsed && userIconCollapsedClass"
        />
      </template>
      <div>
        <div :class="menuTitleClass">
          {{ authStore.user?.firstName }} {{ authStore.user?.lastName }}
        </div>
        <MenuItem
          :icon="ICONS.LOGOUT"
          @click="onLogout"
          test-id="menu-item-logout"
          :label="t('profile_menu.actions.logout.label')"
        />
      </div>
    </Menu>
  </div>
</template>
