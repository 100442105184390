import { RouteRecordRaw } from 'vue-router';
import { fringeRoutesDictionary } from './fringe-routes.dictionary.ts';

export const fringeRoutesConfig: RouteRecordRaw = {
  ...fringeRoutesDictionary.fringe.root,
  redirect: {
    name: fringeRoutesDictionary.fringe.updateMid.name,
  },
  children: [
    {
      ...fringeRoutesDictionary.fringe.updateMid,
      component: () => import('../pages/UpdateMid/UpdateMidPage.vue'),
    },
    {
      ...fringeRoutesDictionary.fringe.searchMid,
      component: () => import('../pages/SearchMid/SearchMidPage.vue'),
    },
  ],
};
