import { RouteRecordRaw } from 'vue-router';
import { reportsRoutesDictionary } from './reports-routes.dictionary.ts';

export const reportsRoutesConfig: RouteRecordRaw = {
  ...reportsRoutesDictionary.reports.root,
  redirect: { name: reportsRoutesDictionary.reports.cardsShipped.name },
  children: [
    {
      ...reportsRoutesDictionary.reports.cardsShipped,
      component: () =>
        import('../pages/CardsShipped/List/CardsShippedList.vue'),
    },
  ],
};
