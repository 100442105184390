import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const billingRoutesDictionary = moduleRouteDictionaryFactory(
  'billing',
  'billing',
  {
    fringeOrders: 'fringe-orders',
    fringeOrderDetail: 'fringe-orders/:id',
  },
);
