import { RouteRecordRaw } from 'vue-router';
import { bankTransfersRoutesDictionary } from './bank-transfers-routes.dictionary.ts';

export const bankTransfersRoutesConfig: RouteRecordRaw = {
  ...bankTransfersRoutesDictionary.bankTransfers.root,
  redirect: { name: bankTransfersRoutesDictionary.bankTransfers.list.name },
  children: [
    {
      ...bankTransfersRoutesDictionary.bankTransfers.list,
      component: () => import('../pages/List/ListBankTransfersPage.vue'),
    },
    {
      ...bankTransfersRoutesDictionary.bankTransfers.associateCreditOrders,
      component: () =>
        import('../pages/AssociateCreditOrders/AssociateCreditOrders.vue'),
    },
    {
      ...bankTransfersRoutesDictionary.bankTransfers.associateCreditOrdersRecap,
      component: () =>
        import(
          '../pages/AssociateCreditOrdersRecap/AssociateCreditOrdersRecap.vue'
        ),
    },
  ],
};
