import { RouteRecordRaw } from 'vue-router';
import { authRoutesDictionary } from './auth-routes.dictionary';
import AuthRouter from '../AuthRouter.vue';

export const authRoutesConfig: RouteRecordRaw = {
  ...authRoutesDictionary.auth.root,
  component: AuthRouter,
  redirect: { name: authRoutesDictionary.auth.login.name },
  children: [
    {
      ...authRoutesDictionary.auth.login,
      component: () => import('../pages/Login/LoginPage.vue'),
    },
  ],
};
