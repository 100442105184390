import { RouteRecordRaw } from 'vue-router';
import { billingRoutesDictionary } from './billing-routes.dictionary.ts';

export const billingRoutesConfig: RouteRecordRaw = {
  ...billingRoutesDictionary.billing.root,
  redirect: { name: billingRoutesDictionary.billing.fringeOrders.name },
  children: [
    {
      ...billingRoutesDictionary.billing.fringeOrders,
      component: () =>
        import('../pages/FringeOrder/List/FringeOrderListPage.vue'),
    },
    {
      ...billingRoutesDictionary.billing.fringeOrderDetail,
      component: () =>
        import('../pages/FringeOrder/Detail/FringeOrderDetailPage.vue'),
      props: true,
    },
  ],
};
