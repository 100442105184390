import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const cardOrderRoutesDictionary = moduleRouteDictionaryFactory(
  'cardOrder',
  'card-orders',
  {
    list: 'list',
    detail: 'detail/:orderId',
    reorderCard: 'reorder-card',
  },
);
