import { RouteRecordRaw } from 'vue-router';
import { cardOrderRoutesDictionary } from './card-order-routes.dictionary.ts';

export const cardOrderRoutesConfig: RouteRecordRaw = {
  ...cardOrderRoutesDictionary.cardOrder.root,
  redirect: { name: cardOrderRoutesDictionary.cardOrder.list.name },
  children: [
    {
      ...cardOrderRoutesDictionary.cardOrder.list,
      component: () => import('../pages/List/CardOrderList.vue'),
    },
    {
      ...cardOrderRoutesDictionary.cardOrder.detail,
      component: () => import('../pages/Detail/CardOrderDetail.vue'),
      props: true,
    },
    {
      ...cardOrderRoutesDictionary.cardOrder.reorderCard,
      component: () => import('../pages/ReorderCards/ReorderCardStepper.vue'),
      props: true,
    },
  ],
};
