import { RouteRecordRaw } from 'vue-router';
import { reimbursementRoutesDictionary } from './reimbursement-routes.dictionary.ts';

export const reimbursementsRoutesConfig: RouteRecordRaw = {
  ...reimbursementRoutesDictionary.reimbursement.root,
  redirect: { name: reimbursementRoutesDictionary.reimbursement.list.name },
  children: [
    {
      ...reimbursementRoutesDictionary.reimbursement.list,
      component: () => import('../pages/List/ListReimbursementPage.vue'),
    },
    {
      ...reimbursementRoutesDictionary.reimbursement.detail,
      component: () => import('../pages/Detail/ReimbursementDetailPage.vue'),
      props: true,
    },
  ],
};
