import { RouteRecordRaw } from 'vue-router';
import { subscriptionRoutesDictionary } from './subscription-routes.dictionary.ts';

export const subscriptionRoutesConfig: RouteRecordRaw = {
  ...subscriptionRoutesDictionary.subscription.root,
  redirect: { name: subscriptionRoutesDictionary.subscription.list.name },
  children: [
    {
      ...subscriptionRoutesDictionary.subscription.list,
      component: () => import('../pages/List/ListSubscriptionPage.vue'),
    },
  ],
};
