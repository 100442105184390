import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const fringeRoutesDictionary = moduleRouteDictionaryFactory(
  'fringe',
  'fringe',
  {
    updateMid: 'update-mid',
    searchMid: 'search-mid',
  },
);
