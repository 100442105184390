import { RouteRecordRaw } from 'vue-router';
import { overBudgetEmployeeRoutesDictionary } from './over-budget-employee.dictionary.ts';

export const overBudgetEmployeeRoutesConfig: RouteRecordRaw = {
  ...overBudgetEmployeeRoutesDictionary.overBudgetEmployees.root,
  redirect: {
    name: overBudgetEmployeeRoutesDictionary.overBudgetEmployees.list.name,
  },
  children: [
    {
      ...overBudgetEmployeeRoutesDictionary.overBudgetEmployees.list,
      component: () => import('../pages/List/OverBudgetEmployeesListPage.vue'),
    },
  ],
};
