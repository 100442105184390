import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const bankTransfersRoutesDictionary = moduleRouteDictionaryFactory(
  'bankTransfers',
  'bankTransfers',
  {
    list: 'list',
    associateCreditOrders: 'associate-credit-orders',
    associateCreditOrdersRecap: 'associate-credit-orders-recap',
  },
);
