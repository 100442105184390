import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const suppliersRoutesDictionary = moduleRouteDictionaryFactory(
  'suppliers',
  'suppliers',
  {
    list: 'list',
    create: 'create',
    update: 'update/:supplierId',
    detail: 'detail/:supplierId',
    overview: 'overview',
    locations: 'locations',
    createLocation: 'locations/create',
    editLocation: 'locations/:locationId/edit/',
    services: 'services',
    createService: 'services/create',
    editService: 'services/:id/edit/',
  },
);
